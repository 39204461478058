/* hanken-grotesk-100 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-100italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-100italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-200 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-200italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-200italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-300 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-300italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-300italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-regular - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-regular.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-500 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-500italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-500italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-600 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-600italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-600italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-700 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-700italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-700italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-800 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-800italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-800italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-900 - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900.woff')
            format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}
/* hanken-grotesk-900italic - cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: hkgrotesk;
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900italic.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900italic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900italic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/hanken-grotesk-v5-cyrillic-ext_latin_latin-ext_vietnamese-900italic.svg#HankenGrotesk')
            format('svg'); /* Legacy iOS */
}

/* poppins-100 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-100italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-100italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-100italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-100italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-200 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-200italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-200italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-200italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-300 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-300italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-regular - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-500 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-500italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-500italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-500italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-600 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-600italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-600italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-600italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-700 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-700italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-800 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-800italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-800italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-800italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}
/* poppins-900 - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900italic - devanagari_latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-900italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-900italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-devanagari_latin_latin-ext-900italic.svg#Poppins')
            format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Material Design Icons';
    src: url(../fonts/materialdesignicons-webfont.eot?v=6.5.95);
    src: url(../fonts/materialdesignicons-webfont.eot?#iefix&v=6.5.95) format('embedded-opentype'),
        url(../fonts/materialdesignicons-webfont.woff2?v=6.5.95) format('woff2'),
        url(../fonts/materialdesignicons-webfont.woff?v=6.5.95) format('woff'),
        url(../fonts/materialdesignicons-webfont.ttf?v=6.5.95) format('truetype');
    font-weight: 400;
    font-style: normal;
}
